import { ParsedUrlQuery } from 'querystring';
import { AvailabilityFilterKey, BusinessPaymentTypeFilterKey, DefaultBaseFilterSetup, PrivatePaymentTypeFilterKey } from '../constants/site-consts';
import { getPageByDataTypeAlias, getPageById } from './api';
import { getAllBundles } from './api/hire/hessel-hire-bundles-api';
import { umbraco } from './api/models';
import { DealershipInformationPage, FilterConfiguration } from './api/models/umbraco';
import {
    CarExplorationModule,
    ContentSpotSettings,
    ProductListWithFilter,
    ShopProductList,
    SixBySixBundleSpot,
} from './api/models/umbraco/content-spot';
import { getShopProductsByCategory } from './api/shop/shop-product-api';
import { getCarExplorationCardData } from './api/vehicle/hessel-vehicle-module-api';
import { getCarByIds } from './api/vehicle/hessel-vehicle-pdp-api';
import { getProductList } from './api/vehicle/hessel-vehicle-plp-api';
import { createShopProductListData } from './mappers/shop/product-list.mapper';
import { mapCarDetailsToProductCard } from './mappers/vehicle/product-card.mapper';
import { createFacetArray } from './mappers/vehicle/product-list.mapper';
import { getSalesVideoDetails, submitActivity } from './api/sales-video/hessel-sales-video-api';
import { SalesVideoData } from './api/models/hessel-api/sales-video';
import { filterMarketingCardOnAvailability } from '../utils/helpers/marketing-card.helpers';

export const ssrForSalesVideo = async (query: ParsedUrlQuery): Promise<SalesVideoData | undefined> => {
    const videoId = query.guid as string | undefined;
    if (!videoId) return;

    const [[salesVideoResponse], [departmentsResponse]] = await Promise.all([
        getSalesVideoDetails(videoId),
        getPageByDataTypeAlias('dealershipInformation'),
    ]);
    if (!salesVideoResponse) return;
    const department = (departmentsResponse as DealershipInformationPage[]).find(
        (d) => Number(d.hovedafdelingId) === salesVideoResponse?.departmentId
    );
    if (!department) return;

    await submitActivity({ videoDetailId: salesVideoResponse.id, activityType: 'SeenByCustomer' });
    return { ...salesVideoResponse, department };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ssrForProductSpots = async (
    page: umbraco.Page,
    globalPlpSettings: umbraco.GlobalProductListSettings,
    query: ParsedUrlQuery
): Promise<string[]> => {
    const allPromises: Promise<unknown>[] = [];
    const allCacheTags: string[] = [];
    if (!('contentSpotsSettings' in page)) return [];
    const productListSpots = page.contentSpotsSettings.filter(
        (x) => x.alias === 'productListWithFilter' || x.alias === 'productListWithoutFilter' || x.alias === 'productRibbon'
    );
    let filterQuery: string[] = [];
    if (query.filter) {
        if (Array.isArray(query.filter)) {
            filterQuery = [...query.filter];
        } else {
            filterQuery.push(query.filter);
        }
    }
    for (const spot of productListSpots) {
        allPromises.push(vehicleProductListSSR(spot, globalPlpSettings, query, filterQuery, allCacheTags));
    }

    const sixPlusSixSpots = page.contentSpotsSettings.filter((x) => x.alias === 'sixPlusSixBundle');
    for (const spot of sixPlusSixSpots) {
        allPromises.push(sixPlusSixBundleSSR(spot as SixBySixBundleSpot, allCacheTags));
    }

    // Super modules
    const carExplorationModules = page.contentSpotsSettings.filter((x) => x.alias === 'carExplorationModule');
    for (const spot of carExplorationModules) {
        allPromises.push(carExplorationModuleSSR(spot as CarExplorationModule));
    }

    //Shop products
    const shopProductListSpots = page.contentSpotsSettings.filter((x) => x.alias === 'shopProductList');
    for (const spot of shopProductListSpots) {
        allPromises.push(shopProductListSSR(spot as ShopProductList, query, page, globalPlpSettings, allCacheTags));
    }
    await Promise.all(allPromises);
    return allCacheTags;
};

const vehicleProductListSSR = async (
    spot: ContentSpotSettings,
    globalPlpSettings: umbraco.GlobalProductListSettings,
    query: ParsedUrlQuery,
    filterQuery: string[],
    cacheTags: string[]
) => {
    let paginationSize = globalPlpSettings.paginationSize;
    let config = 'HireTestConfig';
    const baseFilterArray = (spot as ProductListWithFilter).baseFilterSetup ?? [];
    const baseFilter = baseFilterArray.length > 0 ? baseFilterArray[0] : DefaultBaseFilterSetup;
    if (spot.alias === 'productListWithFilter' || spot.alias === 'productListWithoutFilter' || spot.alias === 'productRibbon') {
        if (spot.alias === 'productListWithFilter' || spot.alias === 'productListWithoutFilter') {
            const pageParam = query.page;
            if (pageParam) {
                const convertedPageParam = Number(pageParam);
                if (!Number.isNaN(convertedPageParam)) {
                    paginationSize = convertedPageParam * globalPlpSettings.paginationSize;
                }
            }
        }
        const [filterConfigurationPage, error] = await getPageById(spot.filterConfiguration.key);
        if (filterConfigurationPage && !error) {
            config = filterConfigurationPage[0].key;
            const marketingProductCards = (filterConfigurationPage[0] as FilterConfiguration).marketingProductCards.filter(
                (x) =>
                    x.index <= paginationSize &&
                    (x?.availabilityList?.length ? filterMarketingCardOnAvailability(baseFilter.defaultAvailability, x?.availabilityList) : true)
            );
            paginationSize = paginationSize - marketingProductCards.length;
        }
    }
    if (spot.alias === 'productRibbon') paginationSize = spot.maxNumberOfProducts === 0 ? 12 : spot.maxNumberOfProducts;

    const allFilters =
        spot.alias === 'productRibbon'
            ? [`${AvailabilityFilterKey}__${baseFilter.defaultAvailability}`]
            : [...filterQuery, `${AvailabilityFilterKey}__${baseFilter.defaultAvailability}`];
    if (baseFilter.defaultAvailability !== 'Demo') {
        allFilters.push(
            `${
                baseFilter.alias === 'vanBaseFilter'
                    ? BusinessPaymentTypeFilterKey
                    : baseFilter.showAsCompanyCars
                    ? BusinessPaymentTypeFilterKey
                    : PrivatePaymentTypeFilterKey
            }__${baseFilter.defaultPaymentType ?? DefaultBaseFilterSetup.defaultPaymentType}`
        );
    }

    const vehicleType = baseFilter.alias === 'carBaseFilter' ? 'Car' : 'Van';
    const [result, error] = await getProductList(
        {
            configurationId: config,
            filters: allFilters,
            from: 0,
            take: paginationSize,
        },
        vehicleType
    );
    if (result && !error) {
        (spot as ProductListWithFilter).initialProductListData = result;
        (spot as ProductListWithFilter).initialProducts = [...result.vehicles.map((x) => mapCarDetailsToProductCard(x))];
        (spot as ProductListWithFilter).initialFacetsArray = createFacetArray(result, globalPlpSettings, baseFilter);
        (spot as ProductListWithFilter).initialActiveFilters = query.filter ?? '';
        cacheTags.push(...result.vehicles.map((x) => x.id));
    }
};

const sixPlusSixBundleSSR = async (spot: SixBySixBundleSpot, cacheTags: string[]) => {
    const [result, error] = await getAllBundles();
    if (result && !error) {
        const allVehicleIds: string[] = result.bundles.reduce((acc, curr) => (acc = [...acc, curr.variant1Id, curr.variant2Id]), new Array<string>());
        const [vehiclesInBundles, vehiclesError] = await getCarByIds(allVehicleIds);
        if (vehiclesInBundles && Array.isArray(vehiclesInBundles) && !vehiclesError) {
            (spot as SixBySixBundleSpot).bundles = result.bundles.filter(
                (x) => vehiclesInBundles.find((vh) => vh.vehicleId === x.variant1Id) && vehiclesInBundles.find((vh) => vh.vehicleId === x.variant2Id)
            );
            (spot as SixBySixBundleSpot).carsInBundle = vehiclesInBundles;
            cacheTags.push(...vehiclesInBundles.map((x) => x.id));
        }
    }
};

const carExplorationModuleSSR = async (spot: CarExplorationModule) => {
    const ceSpot = spot as CarExplorationModule;
    // Flatten all cards to prep for request
    const allCardsFromUmbraco = ceSpot.tabs.map((x) => x.cards).reduce((acc, curr) => [...acc, ...curr], []);
    // Request and set data as extra data on spot.
    const [cardData, error] = await getCarExplorationCardData(ceSpot.priceType, allCardsFromUmbraco);
    if (cardData && !error) {
        ceSpot.cardData = cardData;
    }
};

const shopProductListSSR = async (
    spot: ShopProductList,
    query: ParsedUrlQuery,
    page: umbraco.Page,
    globalPlpSettings: umbraco.GlobalProductListSettings,
    cacheTags: string[]
) => {
    const shopPlpSpot = spot as ShopProductList;
    const pageParam = query.page;
    const filterConfigId = shopPlpSpot.shopFilterConfiguration?.key ?? '';

    let paginationSize = globalPlpSettings.shopPaginationSize;
    if (pageParam) {
        const convertedPageParam = Number(pageParam);
        if (!Number.isNaN(convertedPageParam)) {
            paginationSize = convertedPageParam * globalPlpSettings.shopPaginationSize;
        }
    }
    if (shopPlpSpot.shopFilterConfiguration && shopPlpSpot.shopFilterConfiguration.marketingProductCards.length > 0) {
        const marketingProductCardsOnFirstPage = shopPlpSpot.shopFilterConfiguration.marketingProductCards.filter((x) => x.index <= paginationSize);
        paginationSize = paginationSize - marketingProductCardsOnFirstPage.length;
    }
    const ssrFilters = Array.isArray(query.filter) ? query.filter : [query.filter];
    const sortParam = Array.isArray(query.sort) ? query.sort[0] : query.sort;
    const [[filterResponse, error], [parentPage, parentPageError]] = await Promise.all([
        getShopProductsByCategory({
            categoryId: shopPlpSpot.categoryId,
            filters: query.filter !== undefined && ssrFilters.length > 0 ? ssrFilters : [],
            take: paginationSize,
            from: 0,
            sort: sortParam && sortParam.length > 0 ? sortParam : undefined,
            filterConfigId: filterConfigId,
        }),
        getPageById(page.parentId),
    ]);
    const subCategories = page.children?.filter((x) => x.contentTypeAlias === 'shopCategoryPage');
    if (subCategories && subCategories.length > 0) {
        const mappedPages = subCategories.map((x) => x as umbraco.ShopCategoryPage);
        shopPlpSpot.categories = mappedPages;
    } else if (parentPage && parentPage.length > 0 && !parentPageError) {
        const siblings = parentPage[0].children?.filter((x) => x.contentTypeAlias === 'shopCategoryPage');
        if (siblings && siblings.length > 0) {
            const mappedPages = siblings.map((x) => x as umbraco.ShopCategoryPage);
            shopPlpSpot.categories = mappedPages;
        }
    }
    if (parentPage && parentPage.length > 0) {
        if (parentPage[0].contentTypeAlias === 'shopCategoryPage') shopPlpSpot.backLink = parentPage[0] as umbraco.ShopCategoryPage;
    }
    if (filterResponse && !error) {
        const shopFacets = createShopProductListData(filterResponse.aggregates, globalPlpSettings);
        const selectedSorting = filterResponse.selectedSorting ? filterResponse.selectedSorting.key : '';
        shopPlpSpot.ssrProductListData = {
            facets: shopFacets,
            products: filterResponse.products,
            filters: query.filter ?? '',
            total: filterResponse.total,
            selectedSorting,
            sortOptions: filterResponse.sortOptions.map((x) => x.key),
            filterConfigId: filterConfigId,
        };
        cacheTags.push(...filterResponse.products.map((x) => x.id));
    }
};
