import { VehicleApiVersion } from '../../../constants/api-const';
import { postAPI } from '../helpers';
import { ExplorationCard, ExplorationContentCard } from '../models/umbraco/car-exploration-module.types';
import { CarExplorationCardDataResponse } from '../models/umbraco/content-spot';
import { APIResponse } from '../types';

const Vehicle_BASE_API_URL = `api/${VehicleApiVersion}`;

type CarExplorationDataRequestEntry = {
    category: string;
    brand: string;
    model: string;
    bodyType?: string;
};

export const getCarExplorationCardData = async (
    priceType: string,
    requestData: Array<ExplorationCard | ExplorationContentCard>
): APIResponse<CarExplorationCardDataResponse> => {
    const mappedRequestEntries: CarExplorationDataRequestEntry[] = requestData
        .filter((x) => {
            if (x.alias === 'staticContentCard') return false;
            return true;
        })
        .map((card) => {
            const cardWithTypeCast = card as ExplorationCard;
            const [_, category, ...brand] = cardWithTypeCast.alias.split('x');
            return {
                category,
                brand: brand.join('-'),
                model: cardWithTypeCast.model,
                bodyType: cardWithTypeCast.bodyType,
            };
        });

    // Mapping from content creator friendly naming to actual API naming
    let actualPriceType = priceType;
    switch (priceType) {
        case 'HiRE':
            actualPriceType = 'Hire';
            break;
        case 'Private Leasing':
            actualPriceType = 'PrivateLeasing';
            break;
        case 'Operationel Leasing':
            actualPriceType = 'OperationalLeasing';
            break;
        case 'Finansiel Leasing':
            actualPriceType = 'FinancialLeasing';
            break;
        case 'Finansiering':
            actualPriceType = 'Financing';
            break;
        case 'Cash':
            actualPriceType = 'Cash';
            break;
        case 'Beskatning Fleet':
            actualPriceType = 'FleetTaxation';
            break;
        case 'Beskatning Solution':
            actualPriceType = 'SolutionTaxation';
            break;
        default:
            break;
    }

    return postAPI<CarExplorationCardDataResponse>(`${Vehicle_BASE_API_URL}/vehicles/superModule`, {
        priceType: actualPriceType,
        superModuleFilters: mappedRequestEntries,
    });
};
